import React, { FC } from 'react'
import MenuLink, {
  IMenuLink,
} from 'src/components/side-menu/sub-elements/MenuLink'
import SmallBackButton from 'src/components/side-menu/sub-elements/SmallBackButton'

interface IMenuLinkSubMenuProps {
  upperIndex: number
  menuLinks: IMenuLink[]
  isOpen: Boolean
  title: string
  displayIndex: boolean
}

const MenuLinkSubMenu: FC<IMenuLinkSubMenuProps> = ({
  upperIndex,
  displayIndex,
  menuLinks,
  isOpen,
  title,
}) => {
  const ListOfMenuLinks = (): JSX.Element | JSX.Element[] =>
    menuLinks.map((menuLink) => (
      <MenuLink
        menuLink={menuLink}
        key={menuLink.name}
        upperIndex={upperIndex}
      />
    ))

  return (
    <div
      className={`bg-white py-1.5 
        ${isOpen ? 'block' : 'hidden'}
      `}
    >
      <SmallBackButton className="mb-3 px-5 bg-white" />
      <h4 className="text-grayBlue px-5 font-bold uppercase flex items-start text-sm mb-2">
        <div className="inlineblock w-5 flex-shrink-0">
          {displayIndex && upperIndex}
        </div>
        <div className="">{title}</div>
      </h4>
      <ul className="">{ListOfMenuLinks()}</ul>
    </div>
  )
}

export default MenuLinkSubMenu
