import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  getSeasonFromLocalStorage,
  saveSeasonToLocalStorage,
  getSeasonFromSlug,
} from 'src/helpers/seasons'
import lawsStore from 'src/components/laws/laws-store'
import Message from 'src/components/ui/Message'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ISeason } from 'src/seasons'

const { latestSeason, previousSeason, seasons } = require('src/seasons')

const RestoreSavedSeason = () => {
  const setSelectedSeason = lawsStore((state) => state.setSelectedSeason)
  const [isShown, setIsShown] = useState(false)
  const { t } = useTranslation('laws')

  const isLatestSeasonDefault = useStaticQuery(graphql`
    query {
      datoCmsDefaultSeason {
        latest
      }
    }
  `).datoCmsDefaultSeason.latest

  const getDefaultSeason = () => isLatestSeasonDefault ? latestSeason : previousSeason

  const isSeasonInUrlDifferentThanSelected = () => {
    const currentLocalStorageSeason = getSeasonFromLocalStorage()
    const seasonInUrl = getSeasonFromSlug()

    return seasonInUrl && seasonInUrl.slug !== currentLocalStorageSeason.slug
  }

  const isSavedSeasonUnavailable = () => {
    const savedSeason = getSeasonFromLocalStorage()

    if (!savedSeason) {
      return false
    }

    return !seasons.find(
      (availableSeason) =>
        availableSeason.id === savedSeason.id &&
        availableSeason.slug === savedSeason.slug
    )
  }

  const setAndSaveSeason = (season: ISeason) => {
    setSelectedSeason(season)
    saveSeasonToLocalStorage(season)
  }

  const setInitialValueOfSelectedSeason = () => {
    const seasonInLocalStorage = getSeasonFromLocalStorage()
    const seasonFromSlug = getSeasonFromSlug()

    if (!seasonInLocalStorage) {
      if (!seasonFromSlug) {
        setAndSaveSeason(getDefaultSeason())
        return
      }

      setAndSaveSeason(seasonFromSlug)
      return
    }

    if (isSeasonInUrlDifferentThanSelected()) {
      setIsShown(true)
      setAndSaveSeason(seasonFromSlug)
      return
    }

    if (isSavedSeasonUnavailable()) {
      setIsShown(true)
      setAndSaveSeason(seasonFromSlug || getDefaultSeason())
      return
    }

    const seasonToSet = seasons.find(
      (season) => season.id === seasonInLocalStorage.id
    )
    setSelectedSeason(seasonToSet)
  }

  useEffect(() => {
    setInitialValueOfSelectedSeason()
  }, [])

  const handleCloseClick = () => {
    setIsShown(false)
  }

  return (
    <Message isShown={isShown} onCloseClick={handleCloseClick} isWarning>
      {t('You are about to change to another season.')}
    </Message>
  )
}

export default RestoreSavedSeason
