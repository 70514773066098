import React, { FC, useEffect, useState } from 'react'
import { IMenuItem } from 'src/components/side-menu'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import { IMenuLink } from 'src/components/side-menu/sub-elements/MenuLink'
import { isLargeScreen } from 'src/helpers/layout'
import MenuLinkSubMenu from 'src/components/side-menu/sub-elements/MenuLinkSubMenu'
import { useI18next, Link, useTranslation } from 'gatsby-plugin-react-i18next'

export interface IMenuElement extends IMenuItem {
  menuLinks?: IMenuLink[]
  displayIndex?: boolean
}

interface IMenuElementProps {
  menuElement: IMenuElement
  index: number
}

const MenuElement: FC<IMenuElementProps> = ({ menuElement, index }) => {
  const { t } = useTranslation('menu')
  const { navigate } = useI18next()

  const [isActivePage, setIsActivePage] = useState(false)
  const currentLocationPathname = sideMenuStore(
    (state) => state.currentLocationPathname
  )

  const setSelectedMenuElement = sideMenuStore(
    (state) => state.setSelectedMenuElement
  )
  const selectedMenuElement = sideMenuStore(
    (state) => state.selectedMenuElement
  )
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu)

  const checkIfPageIsActive = () => {
    const active =
      window?.location?.pathname &&
      window.location.pathname.endsWith(menuElement.link)
    setIsActivePage(active)
    if (active && menuElement.menuLinks && isLargeScreen()) {
      setSelectedMenuElement(menuElement.id)
    }
  }

  useEffect(() => {
    checkIfPageIsActive()
  }, [currentLocationPathname])

  const isSelected = selectedMenuElement === menuElement.id

  const handleClick = (): void => {
    navigate(menuElement.link)

    if (isSelected || !isLargeScreen()) {
      toggleSideMenu()
      return
    }

    if (menuElement.menuLinks) {
      setSelectedMenuElement(menuElement.id)
    }
  }

  const MenuLinkSubmenuIfDefined = () => {
    if (menuElement.menuLinks) {
      return (
        <MenuLinkSubMenu
          isOpen={isSelected}
          upperIndex={index}
          menuLinks={menuElement.menuLinks}
          title={menuElement.name}
          displayIndex={menuElement.displayIndex}
        />
      )
    }

    return <></>
  }

  const linkContent = (
    <>
      {menuElement.displayIndex && (
        <span className="w-5 flex-shrink-0">{index}</span>
      )}
      {t(menuElement.name)}
    </>
  )
  const linkStyles = `appearance-none bg-navyBlueGray text-deepDarkBlue cursor-pointer text-sm uppercase py-1.5 px-4 text-left flex items-start w-full lg:text-grayBlue lg:bg-white lg:border-0 flex-shrink-0
    ${isActivePage ? 'font-bold active-page' : ''}
  `

  const MenuElementLink = () => {
    if (menuElement.menuLinks) {
      return (
        <button onClick={handleClick} type="button" className={linkStyles}>
          {linkContent}
        </button>
      )
    }

    return (
      <Link
        to={menuElement.link}
        onClick={handleClick}
        type="button"
        activeClassName="active-page"
        className={linkStyles}
      >
        {linkContent}
      </Link>
    )
  }

  return (
    <li>
      <div className={`${selectedMenuElement ? 'hidden' : 'block'}`}>
        {MenuElementLink()}
      </div>
      {MenuLinkSubmenuIfDefined()}
    </li>
  )
}

export default MenuElement
