import { ISeason } from 'src/seasons'
import { ILanguage } from 'src/i18n'
import { ILawVolume, ISection } from 'src/components/laws'
import { ISubCategory } from 'src/components/side-menu/sub-elements/SubCategory'
import { IMenuLink } from 'src/components/side-menu/sub-elements/MenuLink'
import { IMenuElement } from 'src/components/side-menu/sub-elements/MenuElement'

const { getChapterSlug } = require('src/helpers/laws-in-common-js')

const BASIC_LAWS_SLUG = 'laws-1-17'
interface ISeasonLocaleVolumes {
  locale: ILanguage['code']
  season: ISeason
  volumes: ILawVolume[]
}

const mapSectionsToMenuLinks = (
  sections: ISection[],
  chapterLink: string,
  displayIndex: boolean
): IMenuLink[] =>
  sections.map((section) => ({
    name: section.lawSectionCategoryName,
    link: `${chapterLink}#${section.slug}`,
    id: `${chapterLink}#${section.slug}`,
    index: section.sectionIndex,
    displayIndex,
  }))

const mapChaptersToMenuElements = (
  basicLaws: ILawVolume,
  selectedSeason: ISeason,
  chapterPrePath = ''
): IMenuElement[] =>
  basicLaws.chapters.map((chapter) => {
    const chapterSlug = getChapterSlug(
      chapter.displays,
      basicLaws.id,
      selectedSeason.id
    )

    const chapterLink = `/laws/${selectedSeason.slug}/${chapterPrePath}${chapterSlug}/`
    const displayIndex = basicLaws.slug === BASIC_LAWS_SLUG
    const displayMenuLinks = chapter.sections.length > 1

    const getMenuLinks = () => {
      if (!displayMenuLinks) {
        return null
      }

      return mapSectionsToMenuLinks(chapter.sections, chapterLink, displayIndex)
    }

    return {
      menuLinks: getMenuLinks(),
      name: chapter.title,
      link: chapterLink,
      id: chapterSlug,
      displayIndex,
    }
  })

export const mapLawsToMenuStructure = (
  laws: ILawVolume,
  selectedSeason: ISeason,
  volumeSlug: string,
  chapterPrePath: string = ''
): ISubCategory => {
  const subcategories = mapChaptersToMenuElements(
    laws,
    selectedSeason,
    chapterPrePath
  )

  return {
    name: laws.title,
    id: volumeSlug,
    elements: subcategories,
  }
}

export const extractBasicLaws = (
  seasonLocaleVolumes: ISeasonLocaleVolumes,
  volumeSlug: string
): ILawVolume =>
  seasonLocaleVolumes.volumes.find((volume) => volume.slug === volumeSlug)

export const getVolumesBySeasonAndLocale = (
  locale: ILanguage['code'],
  season: ISeason['year'],
  allLaws: ISeasonLocaleVolumes[]
) =>
  allLaws.find(
    (seasonLocaleLaw) =>
      seasonLocaleLaw.locale === locale &&
      seasonLocaleLaw.season.year === season
  )

export const toggleExplanationOnHover = (
  setIsExplanationShown: (newState: boolean) => void,
  setBoxPosition: (coordinates: { x: number; y: number }) => void,
  setExplanationContent: (text: string) => void
) => {
  const explanationElements = document.querySelectorAll(
    'addedexplanation, deletedexplanation'
  )

  const showExplanation = (
    e: MouseEvent & {
      target: HTMLElement & { dataset: { explanation: string } }
    }
  ) => {
    const lawsContainer = document.getElementById('laws-container')

    setExplanationContent(e.target.dataset.explanation)

    const el = e.target as HTMLElement
    const elOffset = window.pageYOffset + el.getBoundingClientRect().top
    const lawsContainerOffset =
      window.pageYOffset + lawsContainer.getBoundingClientRect().top

    const y = elOffset - lawsContainerOffset

    setBoxPosition({ x: 0, y })
    setIsExplanationShown(true)
  }

  explanationElements.forEach((element) => {
    element.addEventListener('click', showExplanation)
  })

  return () => {
    explanationElements.forEach((element) => {
      element.removeEventListener('click', showExplanation)
    })
  }
}
