const toShortNewsDate = (
  dateString: string,
  locale: string = 'en-EN',
  yearFormat: '2-digit' | 'numeric' = '2-digit'
): string => {
  const {
    day,
    month,
    year,
  }: { [key: string]: string } = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'short',
    year: yearFormat,
  })
    .formatToParts(new Date(dateString))
    .reduce((acc, current) => ({ ...acc, [current.type]: current.value }), {})

  return `${day} ${month.replace('.', '').toUpperCase()} ${year}`
}
export default toShortNewsDate
