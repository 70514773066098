import React, { FC, useState, useEffect, useContext } from 'react'
import MenuElement, {
  IMenuElement,
} from 'src/components/side-menu/sub-elements/MenuElement'
import ArrowIcon from 'src/components/icons/ArrowIcon'
import {
  IMenuItem,
  SHOW_UPPER_ELEMENT_ON_MOBILE,
} from 'src/components/side-menu'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import { useIsLargeScreen } from 'src/helpers/layout'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import {
  useI18next,
  useTranslation,
  Link,
  I18nextContext,
} from 'gatsby-plugin-react-i18next'
import { isAnyElementInsideSubcategoryActive } from 'src/helpers/side-menu'

export interface ISubCategory extends IMenuItem {
  elements?: IMenuElement[]
}

interface ISubCategoryProps {
  subCategory: ISubCategory
  index: number
}

const SubCategory: FC<ISubCategoryProps> = ({ subCategory }) => {
  const { t } = useTranslation('menu')
  const store = sideMenuStore()
  const currentLocationPathname = sideMenuStore(
    (state) => state.currentLocationPathname
  )
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu)
  const { language } = useContext(I18nextContext)
  const [isChildLinkActive, setIsChildLinkActive] = useState(
    isAnyElementInsideSubcategoryActive(subCategory, language)
  )

  const isOtherSubcategorySelected = () =>
    store.selectedSubcategory && store.selectedSubcategory !== subCategory.id

  const isCurrentSubCategoryForcedToOpenByLink = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const subCategoryToOpenFromUrl = urlParams.get('side-menu-sub-category')

    return subCategory.id === subCategoryToOpenFromUrl
  }

  const isAnyOtherSubCategoryForcedToOpenByLink = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const subCategoryToOpenFromUrl = urlParams.get('side-menu-sub-category')

    if (subCategoryToOpenFromUrl === null) {
      return false
    }

    return subCategory.id !== subCategoryToOpenFromUrl
  }

  const autoSelectSubCategoryIfNeeded = (isActive: boolean) => {
    if (!subCategory.elements) {
      return
    }

    if (isCurrentSubCategoryForcedToOpenByLink()) {
      store.setSelectedSubcategory(subCategory.id)
    }

    if (isAnyOtherSubCategoryForcedToOpenByLink()) {
      return
    }

    if (isActive) {
      store.setSelectedSubcategory(subCategory.id)
    }
  }

  const updateIsChildLinkActive = () => {
    const isActive = isAnyElementInsideSubcategoryActive(subCategory, language)

    setIsChildLinkActive(isActive)
    autoSelectSubCategoryIfNeeded(isActive)
  }

  useEffect(() => {
    updateIsChildLinkActive()
  }, [currentLocationPathname])

  const { navigate } = useI18next()

  const isLarge = useIsLargeScreen()

  const ListOMenuElements = (): JSX.Element | JSX.Element[] => {
    if (!subCategory.elements) {
      return <></>
    }

    return subCategory.elements.map((element, index) => (
      <MenuElement menuElement={element} key={element.id} index={index + 1} />
    ))
  }

  const isSelected = () => subCategory.id === store.selectedSubcategory

  const handleClick = (): void => {
    if (subCategory.link) {
      navigate(subCategory.link)
      if (!isLarge) {
        toggleSideMenu()
      }
      store.setSelectedSubcategory(null)
      return
    }
    if (isSelected() && isLarge) {
      store.setSelectedSubcategory(null)
      store.setSelectedMenuElement(null)
      return
    }

    if (subCategory.elements) {
      store.setSelectedMenuElement(null)
      store.setSelectedSubcategory(subCategory.id)
    }
  }

  const isArrowShown = () => !!subCategory.elements && isLarge

  const isSubcategoryOpen = () => {
    if (isLarge === null) {
      return true
    }

    if (!isLarge && isOtherSubcategorySelected()) {
      return false
    }

    if (!isLarge && isSelected()) {
      return SHOW_UPPER_ELEMENT_ON_MOBILE
    }

    return true
  }
  const selectedMenuElementClasses = store.selectedMenuElement
    ? 'border-l-8 border-brightYellow'
    : ''

  const linkContent = (
    <>
      {t(subCategory.name)}
      {isArrowShown() ? (
        <ArrowIcon rotate={isSelected() ? 'top' : 'bottom'} />
      ) : (
        ''
      )}
    </>
  )

  const linkStyles = `
     text-sm uppercase py-2.5 px-4 font-bold text-left border-t border-navyBlueGray flex justify-between items-center w-full lg:flex
     bg-grayBlue lg:bg-navyBlueGray lg:border-grayBlue
      ${!isSelected() ? 'cursor-pointer' : ''}
      ${
        isChildLinkActive
          ? 'lg:font-bold'
          : 'text-white lg:text-deepDarkBlue lg:font-normal'
      }
  `

  const SubcategoryLink = () => {
    if (subCategory.elements || !subCategory.link) {
      return (
        <button
          type="button"
          onClick={handleClick}
          onKeyPress={handleClick}
          tabIndex={0}
          className={linkStyles}
        >
          {linkContent}
        </button>
      )
    }

    return (
      <Link
        onClick={handleClick}
        onKeyPress={handleClick}
        to={subCategory.link}
        tabIndex={0}
        className={linkStyles}
        activeClassName="lg:font-bold active-page"
      >
        {linkContent}
      </Link>
    )
  }

  return (
    <li id={subCategory.id}>
      <HeightAnimation isOpen={isSubcategoryOpen()}>
        {SubcategoryLink()}
      </HeightAnimation>
      <HeightAnimation isOpen={isSelected()}>
        <ul
          className={`bg-navyBlueGray lg:bg-white py-2.5 ${selectedMenuElementClasses}`}
        >
          {ListOMenuElements()}
        </ul>
      </HeightAnimation>
    </li>
  )
}

export default SubCategory
