import React from 'react'
import styled from 'styled-components'

enum Directions {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

enum Rotates {
  right = '45deg',
  bottom = '135deg',
  left = '225deg',
  top = '315deg',
}

enum Shifts {
  right = '-20%, 0',
  bottom = '0, -20%',
  left = '20%, 0',
  top = '0, 20%',
}

interface IArrowIconProps {
  color?: string
  rotate?: keyof typeof Directions
  size?: number
  className?: string
}

interface IArrowProps {
  shift: string
  rotate: string
}

export const Arrow = styled.div<IArrowProps>`
  transition: all 0.25s ease-in-out;
  transform: translate(${(props) => props.shift})
    rotate(${(props) => props.rotate});
`

const ArrowIcon = ({ color, rotate, size, className }: IArrowIconProps) => (
  <Arrow
    className={`w-${size} h-${size} border-t-2 border-r-2 border-${color} ${className}`}
    rotate={Rotates[rotate]}
    shift={Shifts[rotate]}
  />
)

ArrowIcon.defaultProps = {
  color: 'navyBlueGray',
  rotate: Directions.right,
  size: 3,
  className: '',
}

export default ArrowIcon
