import {
  IChapter,
  IChapterAudio,
  IChapterDisplay,
  IOverrideChapter,
  IOverrideSection,
} from 'src/components/laws'

export const getChapterSlug = (
  chapterDispalays: IChapterDisplay[],
  volumeId: number,
  seasonId: number
): string =>
  chapterDispalays.find(
    (display) =>
      display.volumeId === volumeId && display.lawVersionId === seasonId
  ).slug

export const getOverrideChapter = (
  chapter: IChapter,
  overrideChapters: IOverrideChapter[],
  overrideSections: IOverrideSection[],
  chapterAudio: IChapterAudio | null,
  seasonSlug: string,
  chapterSlug: string,
) => {
  const newSections = chapter.sections.map((section) => {
    const foundOverrideSection = overrideSections.find(
      (overrideSection) =>
        overrideSection.slug === section.slug &&
        chapterSlug === overrideSection.chapter.slug &&
        overrideSection.seasons.some((it) => it.slug === seasonSlug)
    )
    if (foundOverrideSection) {
      const images = foundOverrideSection.ignoreGallery ? [] : section.images
      return {
        ...section,
        content: foundOverrideSection.contentOverride ?? section.content,
        mobileTitleOverride: foundOverrideSection.mobileTitleOverride,
        desktopTitleOverride: foundOverrideSection.desktopTitleOverride,
        transitionBlock: foundOverrideSection.transitionBlock,
        images,
        audio: chapterAudio?.sections?.[section.slug],
      }
    }
    return {
      ...section,
      audio: chapterAudio?.sections?.[section.slug]
    }
  })

  const overrideChapter = overrideChapters.find((it) => it.slug === chapterSlug)

  return {
    ...chapter,
    sections: newSections,
    mobileHeader: overrideChapter?.mobileHeader,
    desktopHeader: overrideChapter?.desktopHeader,
    seo: overrideChapter?.seo,
    audio: chapterAudio?.recording,
  }
}
