import React, { useEffect } from 'react'
import Header from 'src/components/layout/Header'
import Footer from 'src/components/layout/Footer'
import StickyFooter from 'src/components/layout/StickyFooter'
import { Helmet } from 'react-helmet'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import SideMenu from 'src/components/side-menu'
import { IFeaturedNewsProps } from 'src/components/news/featured/FeaturedNews'
import RestoreSavedSeason from 'src/components/laws/RestoreSavedSeason'
import { ILawsNavigatorProps } from 'src/components/laws/LawsNavigator'
import smoothscroll from 'smoothscroll-polyfill'
import sideMenuStore from '../side-menu/side-menu-store'

interface ILayoutProps {
  featuredNewsItem?: IFeaturedNewsProps
  lawsNavigatorProps?: ILawsNavigatorProps
  children: JSX.Element | JSX.Element[]
}

const detectKeyboardUser = () => {
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse')
  })

  document.body.addEventListener('keydown', (event) => {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse')
    }
  })

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('using-mouse')
  })
}

const isMenuForcedToOpenByLink = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const subCategoryToOpenFromUrl = urlParams.get('side-menu-open')

  return !!subCategoryToOpenFromUrl
}

const Layout: React.FC<ILayoutProps> = ({
  featuredNewsItem,
  lawsNavigatorProps,
  children,
}) => {
  const lngContext = React.useContext(I18nextContext)
  const setIsSideMenuOpen = sideMenuStore((state) => state.setIsSideMenuOpen)

  useEffect(() => {
    smoothscroll.polyfill()
    detectKeyboardUser()

    if (isMenuForcedToOpenByLink()) {
      setIsSideMenuOpen(true)
    }
  }, [])

  return (
    <div className="font-roag overflow-hidden tracking-wide">
      <Helmet
        htmlAttributes={{ lang: lngContext.language }}
        titleTemplate="%s | IFAB"
      >
        <meta charSet="utf-8" />
        <script 
          type="text/javascript" 
          src={`https://cdn.cookielaw.org/consent/${process.env.GATSBY_COOKIE_SCRIPT_ID}/OtAutoBlock.js`} 
        />
        <script 
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" 
          data-document-language="true" 
          type="text/javascript" 
          char-set="UTF-8" 
          data-domain-script={process.env.GATSBY_COOKIE_SCRIPT_ID} 
        />
        <script 
          type="text/javascript"
          dangerouslySetInnerHTML={{__html: 'function OptanonWrapper() { }'}} 
        />
        <link
          as="font"
          href="/fonts/Roag-Regular.woff2"
          rel="preload"
          crossOrigin="anonymous"
        />
        <link
          as="font"
          href="/fonts/Roag-Bold.woff2"
          rel="preload"
          crossOrigin="anonymous"
        />
        <link
          as="font"
          href="/fonts/Roag-UltraLight.woff2"
          rel="preload"
          crossOrigin="anonymous"
        />
        <script
          defer
          data-domain="theifab.com"
          src="https://plausible.io/js/plausible.js"
        />
      </Helmet>
      <Header
        featuredNewsItem={featuredNewsItem}
        lawsNavigatorProps={lawsNavigatorProps}
      />
      <div className="relative">
        <RestoreSavedSeason />
        <SideMenu />
        <main className="main">{children}</main>
        <StickyFooter />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
