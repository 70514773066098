import create from 'zustand'

type SideMenuStore = {
  isSideMenuOpen: boolean
  selectedCategory: string | null | true
  currentUrl: string | null
  currentLocationPathname: string | null
  selectedSubcategory: string | null
  selectedMenuElement: string | null

  toggleSideMenu: () => void
  setIsSideMenuOpen: (value: boolean) => void
  setCurrentAnchor: (url: string) => void
  setCurrentLocationPathname: (url: string) => void
  setSelectedCategory: (category: string | null | true) => void
  setSelectedSubcategory: (subcategory: string | null) => void
  setSelectedMenuElement: (selectedMenuElement: string | null) => void
}

const sideMenuStore = create<SideMenuStore>((set) => ({
  isSideMenuOpen: false,
  currentUrl: null,
  selectedCategory: null,
  selectedSubcategory: null,
  selectedMenuElement: null,
  currentLocationPathname: null,

  toggleSideMenu: () =>
    set((state) => ({ isSideMenuOpen: !state.isSideMenuOpen })),
  setIsSideMenuOpen: (value) => set(() => ({ isSideMenuOpen: value })),
  setSelectedCategory: (category) =>
    set(() => ({ selectedCategory: category })),
  setCurrentAnchor: (url) => set(() => ({ currentUrl: url })),
  setCurrentLocationPathname: (pathname) =>
    set(() => ({ currentLocationPathname: pathname })),
  setSelectedSubcategory: (subcategory) =>
    set(() => ({ selectedSubcategory: subcategory })),
  setSelectedMenuElement: (menuElement) =>
    set(() => ({ selectedMenuElement: menuElement })),
}))

export default sideMenuStore
