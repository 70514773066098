import React, { FC } from 'react'
import ArrowIcon from 'src/components/icons/ArrowIcon'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IBackButtonProps {
  className?: string
}

const BackButton: FC<IBackButtonProps> = ({ className }) => {
  const { t } = useTranslation('menu')
  const setSelectedMenuElement = sideMenuStore(
    (state) => state.setSelectedMenuElement
  )

  const collapseMenu = () => {
    setSelectedMenuElement(null)
  }

  return (
    <button
      onClick={collapseMenu}
      type="button"
      className={`flex items-center uppercase w-full text-sm font-bold ${className}`}
    >
      <ArrowIcon
        rotate="left"
        color="almostBlackBlue"
        size={2}
        className="mr-3"
      />
      <div className="self-center">{t('Go back')}</div>
    </button>
  )
}

export default BackButton
