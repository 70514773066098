/* eslint-disable import/extensions */
const { defaultLng } = require('../i18n')
const { seasons } = require('../seasons.ts')

const getLocaleUrlPrefix = (locale) => {
  if (locale === defaultLng.code) {
    return ''
  }

  return `${locale}`
}

const getSeasonUrlSegment = (remoteSlug) => {
  const foundSeason = seasons.find((season) => season.remoteSlug === remoteSlug)

  return foundSeason && foundSeason.slug
}

const transformDocumentData = (data) => {
  const locales = {}
  
  Object.entries(data).forEach(([key, content]) => {
    content.nodes.forEach((node) => {
      const locale = locales[node.locale] ?? {}
      locales[node.locale] = locale

      const { nodes } = locale[key] ?? { nodes: [] }
      locale[key] = { nodes }
      
      nodes.push(node)
    })
  })
  
  Object.values(locales).forEach((locale) => {
    Object.values(locale).forEach(({ nodes }) => {
      nodes.sort((a, b) => a.position - b.position)
    })
  })

  return locales
}

module.exports = {
  getLocaleUrlPrefix,
  getSeasonUrlSegment,
  transformDocumentData,
}
