import React, { FC, useEffect, useState, useContext } from 'react'
import SubCategory, {
  ISubCategory,
} from 'src/components/side-menu/sub-elements/SubCategory'
import {
  IMenuItem,
  SHOW_UPPER_ELEMENT_ON_MOBILE,
} from 'src/components/side-menu'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import { useIsLargeScreen } from 'src/helpers/layout'
import { isAnyElementActiveInsideCategory } from 'src/helpers/side-menu'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import SeasonSwitchAsCategory from 'src/components/side-menu/sub-elements/SeasonSwitchAsCategory'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import CategoryLink from './CategoryLink'

export interface ICategory extends IMenuItem {
  subcategories?: ISubCategory[]
  isSeasonsSwitch?: boolean
}

interface ICategoryProps {
  category: ICategory
  allCategories: ICategory[]
  className?: string
}

export const mapSubcategoriesToComponents = ({
  subcategories,
  isSeasonsSwitch,
}: ICategory): JSX.Element[] => {
  const subcategoriesToMap = subcategories || []
  const mappedSubcategories = subcategoriesToMap.map((subcategory, index) => (
    <SubCategory
      subCategory={subcategory}
      key={subcategory.name}
      index={index}
    />
  ))

  if (isSeasonsSwitch) {
    return [
      <SeasonSwitchAsCategory key="season-switch" />,
      ...mappedSubcategories,
    ]
  }

  return mappedSubcategories
}

const Category: FC<ICategoryProps> = ({ category, allCategories }) => {
  const [isSubelementActive, setIsSubelementActive] = useState(false)
  const store = sideMenuStore()
  const isLarge = useIsLargeScreen()
  const isSelected = () => category.id === store.selectedCategory
  const { language } = useContext(I18nextContext)
  const currentLocationPathname = sideMenuStore(
    (state) => state.currentLocationPathname
  )

  const clearMenuSelections = () => {
    if (!isLarge) {
      store.setSelectedSubcategory(null)
      store.setSelectedCategory(null)
    }
  }

  useEffect(() => {
    document.querySelectorAll('a[hreflang]').forEach((el) => {
      el.addEventListener('click', clearMenuSelections)
    })

    return () => {
      document.querySelectorAll('a[hreflang]').forEach((el) => {
        el.removeEventListener('click', clearMenuSelections)
      })
    }
  })

  const isCurrentCategoryForcedToOpenByLink = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const categoryToOpenFromUrl = urlParams.get('side-menu-category')

    return category.id === categoryToOpenFromUrl
  }

  const isAnyOtherCategoryForcedToOpenByLink = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const categoryToOpenFromUrl = urlParams.get('side-menu-category')

    if (categoryToOpenFromUrl === null) {
      return false
    }

    return category.id !== categoryToOpenFromUrl
  }

  const isOtherCategorySelected = (): boolean =>
    store.selectedCategory && store.selectedCategory !== category.id

  const autoSelectCategoryIfActive = () => {
    const isActive = isAnyElementActiveInsideCategory(category, language)
    const hasChildrensToRender =
      category.subcategories || category.isSeasonsSwitch

    setIsSubelementActive(isActive)

    if (!hasChildrensToRender) {
      return
    }

    if (isCurrentCategoryForcedToOpenByLink()) {
      store.setSelectedCategory(category.id)
      return
    }

    if (isAnyOtherCategoryForcedToOpenByLink()) {
      return
    }

    if (isActive) {
      store.setSelectedCategory(category.id)
    }
  }

  const deselectCategoriesIfNoneIsActive = () => {
    const isAnyActive = allCategories.find((singleCategory) =>
      isAnyElementActiveInsideCategory(singleCategory, language)
    )

    if (!isAnyActive) {
      store.setSelectedCategory(null)
      store.setSelectedSubcategory(null)
    }
  }

  const updateIsChildLinkActive = () => {
    autoSelectCategoryIfActive()
    deselectCategoriesIfNoneIsActive()
  }

  useEffect(() => {
    updateIsChildLinkActive()
  }, [currentLocationPathname, category.link])

  const SubCategoriesList = () => mapSubcategoriesToComponents(category)

  const isSubcategorySelected = !!store.selectedSubcategory

  const getCategoryShowStatus = () => {
    const isMobile = !isLarge

    if (isLarge === null) {
      return true
    }

    if (isMobile && isSubcategorySelected) {
      return false
    }

    if (isMobile && isOtherCategorySelected()) {
      return false
    }

    if (isMobile && isSelected()) {
      return SHOW_UPPER_ELEMENT_ON_MOBILE
    }

    return true
  }

  return (
    <li id={category.id}>
      <HeightAnimation isOpen={getCategoryShowStatus()}>
        <CategoryLink
          category={category}
          isSelected={isSelected()}
          isSubelementActive={isSubelementActive}
        />
      </HeightAnimation>
      <HeightAnimation isOpen={isSelected()}>
        <ul>{SubCategoriesList()}</ul>
      </HeightAnimation>
    </li>
  )
}

export default Category
