import React, { FC } from 'react'
import { useI18next, I18nextContext } from 'gatsby-plugin-react-i18next'
import { useIsLargeScreen } from 'src/helpers/layout'
import sideMenuStore from 'src/components/side-menu/side-menu-store'

const { languages } = require('src/i18n')

interface ILanguageSwitcherProps {
  className?: string
}
interface ILanguage {
  // eslint-disable-next-line react/no-unused-prop-types
  name: string
  code: string
}

const SingleLanguage = ({ code }: ILanguage) => {
  const { changeLanguage } = useI18next()
  const lngContext = React.useContext(I18nextContext)
  const isActive = code === lngContext.language
  const isLarge = useIsLargeScreen()
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu)

  const changeCurrentLanguage = () => {
    changeLanguage(code)
    if (!isLarge) {
      toggleSideMenu()
    }
  }

  return (
    <li key={code}>
      <button
        type="button"
        onClick={() => changeCurrentLanguage()}
        className={`mr-4 uppercase ${
          isActive ? 'text-brightYellow' : 'text-navyBlueGray cursor-pointer'
        }`}
      >
        {code}
      </button>
    </li>
  )
}

const ListOfLanguages = (className) => (
  <ul className={`flex ${className || ''}`}>
    {languages.map((lng) => SingleLanguage(lng))}
  </ul>
)

const LanguageSwitcher: FC<ILanguageSwitcherProps> = ({ className }) => (
  <>{ListOfLanguages(className)}</>
)

export default LanguageSwitcher
