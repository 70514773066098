import React, { FC, useRef } from 'react'
import SearchInputButton from 'src/components/search/SearchInputButton'
import SearchLabel from 'src/components/search/SearchLabel'

interface ISearchAsMenuCategoryProps {
  iconHeight?: string
}

const SearchAsMenuCategory: FC<ISearchAsMenuCategoryProps> = ({
  iconHeight,
}) => {
  const inputRef = useRef(null)

  const handleClick = () => {
    inputRef.current.showInput()
  }

  return (
    <button
      onClick={handleClick}
      type="button"
      className="relative bg-white cursor-pointer uppercase font-bold py-4 px-5 text-left flex lg:flex justify-between items-center w-full text-xl border-t border-navyBlueGray"
    >
      <SearchLabel className="text-base font-bold uppercase" />
      <SearchInputButton ref={inputRef} height={iconHeight} />
    </button>
  )
}

export default SearchAsMenuCategory
