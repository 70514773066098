// i18next-extract-mark-ns-start search

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ISearchLabelProps {
  className?: string
}

const SearchLabel: FC<ISearchLabelProps> = ({ className }) => {
  const { t } = useTranslation('search')
  return <p className={`${className || ''}`}>{t('Search')}</p>
}
export default SearchLabel
