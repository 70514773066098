import React, { FC } from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import ArrowIcon from 'src/components/icons/ArrowIcon'
import { useIsLargeScreen } from 'src/helpers/layout'
import { navigate } from 'gatsby'
import { ICategory } from './Category'
import { ISubCategory } from './SubCategory'
import sideMenuStore from '../side-menu-store'

interface ICategoryLinkProps {
  className?: string
  category: ICategory
  isSubelementActive: boolean
  isSelected: boolean
}

const CategoryLink: FC<ICategoryLinkProps> = ({
  className,
  category,
  isSubelementActive,
  isSelected,
}) => {
  const { t } = useTranslation('menu')
  const isLarge = useIsLargeScreen()
  const store = sideMenuStore()

  const isArrowShown = (): boolean =>
    (!!category.subcategories || category.isSeasonsSwitch) && isLarge

  const getArrowRotate = () => {
    if (isLarge) {
      if (isSelected) {
        return 'top'
      }

      return 'bottom'
    }

    return 'right'
  }

  const linkStyles = `
    text-white cursor-pointer uppercase font-bold py-3.5 lg:py-4 px-4 text-left flex lg:flex justify-between items-center w-full text-xl lg:text-base border-navyBlueGray
    ${className || ''}
    ${!isSelected ? ' border-b' : ''}
  `

  const categoryStyles = `
    ${linkStyles}
    ${isSubelementActive ? 'text-brightYellow' : ''}
  `

  const handleClick = ({ id, link }: ISubCategory) => {
    if (!isSelected) {
      store.setSelectedSubcategory(null)
      store.setSelectedMenuElement(null)
    }

    if (category.isSeasonsSwitch) {
      if (!isSelected) {
        store.setSelectedCategory(id)
      } else {
        store.setSelectedCategory(null)
      }
    }

    if (link) {
      store.setSelectedSubcategory(null)
      if (!category.isSeasonsSwitch) {
        store.setSelectedCategory(null)
      }

      if (!isLarge) {
        store.toggleSideMenu()
      }

      navigate(link)

      return
    }

    if (isSelected && isLarge) {
      store.setSelectedMenuElement(null)
      store.setSelectedSubcategory(null)
      store.setSelectedCategory(null)
      return
    }

    if (category.subcategories) {
      store.setSelectedMenuElement(null)
      store.setSelectedSubcategory(null)
      store.setSelectedCategory(id)
    }
  }

  if (category.link) {
    return (
      <Link
        onClick={() => handleClick(category)}
        to={category.link}
        activeClassName="active-page text-brightYellow"
        className={categoryStyles}
      >
        {t(category.name)}
        {isArrowShown() ? <ArrowIcon rotate={getArrowRotate()} /> : ''}
      </Link>
    )
  }

  return (
    <button
      onClick={() => handleClick(category)}
      type="button"
      className={categoryStyles}
    >
      {t(category.name)}
      {isArrowShown() ? <ArrowIcon rotate={getArrowRotate()} /> : ''}
    </button>
  )
}
CategoryLink.defaultProps = {
  className: '',
}

export default CategoryLink
