import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ISeason } from 'src/seasons'
import { ILanguage } from 'src/i18n'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import {
  extractBasicLaws,
  getVolumesBySeasonAndLocale,
  mapLawsToMenuStructure,
} from 'src/helpers/laws'
import lawsStore from 'src/components/laws/laws-store'

const BASIC_LAWS_SLUG = 'laws-1-17'

const useLawsMenuStructure = () => {
  const lngContext = React.useContext(I18nextContext)

  const selectedSeason = lawsStore((state) => state.selectedSeason)

  const data = useStaticQuery(graphql`
    query {
      allLaws {
        nodes {
          locale
          season {
            slug
            year
            id
          }
          volumes {
            slug
            sortOrder
            title
            type
            id
            chapters {
              header
              title
              displays {
                slug
                volumeId
                lawVersionId
              }
              sections {
                slug
                lawSectionCategoryName
                sectionIndex: lawSectionCategoryNumeral
              }
            }
          }
        }
      }
    }
  `)

  const getBasicLawsBySeasonAndLocale = (
    locale: ILanguage['code'],
    season: ISeason['year']
  ) =>
    extractBasicLaws(
      getVolumesBySeasonAndLocale(locale, season, data.allLaws.nodes),
      BASIC_LAWS_SLUG
    )

  const basicLawsVolume = getBasicLawsBySeasonAndLocale(
    lngContext.language,
    selectedSeason.year
  )

  return mapLawsToMenuStructure(
    basicLawsVolume,
    selectedSeason,
    BASIC_LAWS_SLUG
  )
}

export default useLawsMenuStructure
