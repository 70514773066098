import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import lawsStore from 'src/components/laws/laws-store'
import { ISubCategory } from 'src/components/side-menu/sub-elements/SubCategory'

const GLOSSARY_SLUG = 'glossary'

const useGlossaryMenuStructure = (): ISubCategory => {
  const { language } = React.useContext(I18nextContext)

  const selectedSeason = lawsStore((state) => state.selectedSeason)

  const data = useStaticQuery(graphql`
    query {
      glossary: allDatoCmsGlossary(sort: { fields: position }) {
        glossarySections: nodes {
          id
          locale
          slug
          title
          season {
            season
            slug
          }
        }
      }
    }
  `)

  return {
    name: 'Glossary',
    id: 'glossary',
    elements: data.glossary.glossarySections
      .filter((section) => section.season.season === selectedSeason.year)
      .filter((section) => section.locale === language)
      .map((section) => ({
        name: section.title,
        menuLinks: null,
        link: `/laws/${selectedSeason.slug}/${GLOSSARY_SLUG}/${section.slug}/`,
        id: section.slug,
        displayIndex: false,
      })),
  }
}

export default useGlossaryMenuStructure
