import React, { FC, useContext } from 'react'
import {
  Link,
  useTranslation,
  I18nextContext,
} from 'gatsby-plugin-react-i18next'

import toShortNewsDate from 'src/helpers/news'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import tw from 'twin.macro'
import themeColors from 'src/helpers/theme-colors'
import DotSvg from 'src/images/icons/dot.svg'

export interface IFeaturedNewsProps {
  title: string
  slug: string
  date: string
}

const tailwind = resolveConfig(tailwindConfig)

const BlinkingImg = styled.img`
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  animation: blink 0.8s ease-out infinite;
`

const Marquee = styled.div`
  @media (min-width: ${tailwind.theme.screens.lg}) {
    ${tw`truncate`}
  }
  @media not screen and (min-width: ${tailwind.theme.screens.lg}) {
    @keyframes marquee {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    min-width: 200%;
    ${tw`inline-block`}
    animation: marquee 15s linear 3s infinite;
  }
`

const FadeLeftRight = styled.div`
    @media not screen and (min-width: ${tailwind.theme.screens.lg}) {
        @keyframes start-fade-out {
            100% { background: linear-gradient(to right, ${themeColors.dirtyRed}, rgba(216, 103, 103, 0) 2em); }
        }
        @keyframes start-fade-in {
            100% { background: linear-gradient(to left, ${themeColors.dirtyRed}, rgba(216, 103, 103, 0) 2em); }
        }
        position: relative;
        :before {
            content:'';
            width:2em;
            height:100%;    
            position:absolute;
            left:0;
            top:0;
            z-index: 10;
            animation start-fade-out 0s step-start 3s 1 normal forwards;
        }
        :after {
            content:'';
            width:2em;
            height:100%;    
            position:absolute;
            right:0;
            top:0;
            animation start-fade-in 0s step-start 3s 1 normal forwards;
        }
    }
`

interface IScrollableHeaderProps {
  title: string
  date: string
  className: string
}

const ScrollableHeader: FC<IScrollableHeaderProps> = ({
  title,
  date,
  className,
}) => {
  const { t } = useTranslation('news')
  const { language } = useContext(I18nextContext)
  return (
    <span className={className}>
      <span className="uppercase font-semibold">{t('Latest News')}</span>
      <span className="text-lg mx-1">＞</span>
      <span>{toShortNewsDate(date, language, 'numeric')}</span>
      <span className="text-lg mx-1">＞</span>
      <span>{title}</span>
    </span>
  )
}

const FeaturedNews: FC<IFeaturedNewsProps> = ({ title, slug, date }) => (
  <Link to={`/news/${slug}`}>
    <div className="bg-dirtyRed py-1">
      <div className="container text-almostBlackBlue whitespace-nowrap flex justify-center items-center">
        <BlinkingImg className="mr-2 w-3 h-3" src={DotSvg} />
        <FadeLeftRight className="overflow-hidden flex-grow lg:flex-grow-0">
          <Marquee>
            <ScrollableHeader
              title={title}
              date={date}
              className="pr-20 lg:pr-0 inline-block lg:inline min-w-1/2 lg:min-w-0"
            />
            <ScrollableHeader
              title={title}
              date={date}
              className="pr-20 inline-block min-w-1/2 lg:hidden"
            />
          </Marquee>
        </FadeLeftRight>
        <span className="text-lg ml-1">＞</span>
      </div>
    </div>
  </Link>
)

export default FeaturedNews
