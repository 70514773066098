import React, { FC, useEffect, useState } from 'react'
import Category, {
  ICategory,
} from 'src/components/side-menu/sub-elements/Category'
import BackButton from 'src/components/side-menu/BackButton'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import useMenuStructure from 'src/components/side-menu/side-menu-structure'
import SearchAsMenuCategory from 'src/components/search/SearchAsMenuCategory'
import lawsStore from 'src/components/laws/laws-store'

interface ICategoriesContainerProps {}
const mapCategoriesToComponents = (categories: ICategory[]): JSX.Element[] =>
  categories.map((category) => (
    <Category
      category={category}
      key={category.name}
      allCategories={categories}
    />
  ))

const CategoriesContainer: FC<ICategoriesContainerProps> = () => {
  const menuStructure = useMenuStructure()
  const [categories, setCategories] = useState(menuStructure)
  const selectedCategory = sideMenuStore((state) => state.selectedCategory)
  const selectedSeason = lawsStore((state) => state.selectedSeason)

  useEffect(() => {
    setCategories(menuStructure)
  }, [selectedSeason.slug])

  return (
    <>
      <div>
        <HeightAnimation isOpen={!!selectedCategory}>
          <BackButton className="lg:hidden" />
        </HeightAnimation>
      </div>
      <ul className="pb-24">
        <HeightAnimation isOpen={!selectedCategory}>
          <div className="lg:hidden">
            <SearchAsMenuCategory iconHeight="h-6" />
          </div>
        </HeightAnimation>
        {mapCategoriesToComponents(categories)}
      </ul>
    </>
  )
}

export default CategoriesContainer
