import React, { FC } from 'react'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import ArrowIcon from 'src/components/icons/ArrowIcon'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IBackButtonProps {
  className?: string
}

const BackButton: FC<IBackButtonProps> = ({ className }) => {
  const { t } = useTranslation('menu')
  const sideMenuState = sideMenuStore()

  const collapseMenu = () => {
    sideMenuState.setSelectedMenuElement(null)

    if (sideMenuState.selectedSubcategory) {
      sideMenuState.setSelectedSubcategory(null)
      return
    }

    if (sideMenuState.selectedCategory) {
      sideMenuState.setSelectedCategory(null)
    }
  }

  return (
    <button
      type="button"
      className={`text-deepDarkBlue bg-white flex items-center w-full py-4 px-5 border-t border-grayBlue ${className}`}
      onClick={collapseMenu}
    >
      <ArrowIcon rotate="left" color="almostBlackBlue" />
      <span className="ml-3 text-almostBlackBlue uppercase font-bold text-base">
        {t('Go back')}
      </span>
    </button>
  )
}

export default BackButton
