import React, { FC } from 'react'
import Switch from 'src/components/ui/Switch'

interface ILabeledSwitchProps {
  isEnabled: boolean
  onChange: () => any
  leftLabel: string
  rightLabel: string
  className?: string
  isGrayBackground?: boolean
  noPadding?: boolean
}

const LabeledSwitch: FC<ILabeledSwitchProps> = ({
  leftLabel,
  rightLabel,
  onChange,
  isEnabled,
  className,
  isGrayBackground,
  noPadding,
}) => (
  <>
    <div
      className={`flex items-center justify-start
        ${noPadding ? '' : 'py-2'}
        ${className}`}
    >
      <button
        type="button"
        onClick={() => (isEnabled ? onChange() : () => {})}
        className={`${
          !isEnabled
            ? 'text-brightYellow font-bold cursor-default'
            : 'text-white cursor-pointer'
        } uppercase text-xl lg:text-base leading-none`}
      >
        {leftLabel}
      </button>
      <div className="leading-none">
        <Switch
          onChange={onChange}
          isEnabled={isEnabled}
          className="mx-4"
          isGrayBackground={isGrayBackground}
        />
      </div>
      <button
        type="button"
        onClick={() => (!isEnabled ? onChange() : () => {})}
        className={`${
          isEnabled
            ? 'text-brightYellow font-bold cursor-default'
            : 'text-white cursor-pointer'
        } uppercase text-xl lg:text-base leading-none`}
      >
        {rightLabel}
      </button>
    </div>
  </>
)

export default LabeledSwitch
