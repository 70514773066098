import React, { FC, useEffect, useRef, useContext } from 'react'
import LanguageSwitcher from 'src/components/language/LanguageSwitcher'
import JoinUsIcons from 'src/components/icons/JoinUsIcons'
import {
  useTranslation,
  I18nextContext,
  Link,
} from 'gatsby-plugin-react-i18next'

interface IStickyFooterProps {}

const StickyFooter: FC<IStickyFooterProps> = () => {
  const stickyFooterRef = useRef<HTMLInputElement>(null)
  const stickyFooterSpacer = useRef<HTMLInputElement>(null)
  const { t } = useTranslation('menu')

  const updateSpacerHeight = () => {
    const extraOffset = 2
    stickyFooterSpacer.current.style.height = `${
      stickyFooterRef.current.offsetHeight - extraOffset
    }px`
  }

  const setFooterPosition = (position: string) => {
    stickyFooterRef.current.style.position = position
  }

  const updateFooterShift = () => {
    if (typeof window === 'undefined') {
      return
    }

    const footerHeight = document.getElementById('footer').clientHeight
    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight
    const bodyHeight = document.querySelector('body').clientHeight
    const scrollableBottomSpace = bodyHeight - (scrollTop + windowHeight)

    updateSpacerHeight()

    if (footerHeight < scrollableBottomSpace) {
      setFooterPosition('fixed')
      return
    }

    setFooterPosition('absolute')
  }

  const updateDynamicLayoutValues = () => {
    updateFooterShift()
  }

  useEffect(() => {
    updateDynamicLayoutValues()

    window.addEventListener('scroll', updateDynamicLayoutValues)
    window.addEventListener('resize', updateDynamicLayoutValues)

    return () => {
      window.removeEventListener('scroll', updateDynamicLayoutValues)
      window.removeEventListener('resize', updateDynamicLayoutValues)
    }
  })

  const { language, defaultLanguage } = useContext(I18nextContext)
  const languageSuffix = language === defaultLanguage ? '/' : `/${language}/`

  return (
    <>
      <div
        id="sticky-footer"
        ref={stickyFooterRef}
        className="fixed bottom-0 inset-x-0 z-20"
      >
        <div className="justify-around items-center py-2 bg-almostBlackBlue text-navyBlueGray uppercase hidden lg:flex">
          <LanguageSwitcher />
          <Link to="/logapp/" activeClassName="text-brightYellow">
            {t('Download mobile app')}
          </Link>
          <a
            href={`https://shop.theifab.com${languageSuffix}`}
            target="_blank"
            rel="noreferrer"
          >
            {t('Visit webshop')}
          </a>
          <JoinUsIcons />
        </div>
      </div>
      <div ref={stickyFooterSpacer} className="hidden lg:block" />
    </>
  )
}

export default StickyFooter
