import React, {
  FC,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import SearchSvg from 'src/images/icons/search.svg'
import SearchSvgWithMargins from 'src/images/icons/search-with-margins.svg'
import styled from 'styled-components'
import { useI18next } from 'gatsby-plugin-react-i18next'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import { useIsLargeScreen } from 'src/helpers/layout'
import ActiveHamburgerIcon from 'src/components/icons/Arrow.inline.svg'

interface ISearchInputButtonProps {
  className?: string
  height?: string
}

const tailwind = resolveConfig(tailwindConfig)

const StyledInput = styled.input`
  @media (min-width: ${tailwind.theme.screens.lg}) {
    right: 80px;
    top: 50%;
    transform: translate(-100%, -50%);
  }
`

const SearchInputButton: FC<ISearchInputButtonProps> = forwardRef(
  (props, ref) => {
    const [isInputShown, setIsInputShown] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const searchInputRef = useRef(null)
    const searchIconRef = useRef(null)
    const { navigate } = useI18next()
    const setIsSideMenuOpen = sideMenuStore((state) => state.setIsSideMenuOpen)
    const isLarge = useIsLargeScreen()

    useImperativeHandle(ref, () => ({
      showInput() {
        setIsInputShown(true)
      },
    }))

    const navigateToResultsPage = () => {
      setInputValue('')
      setIsInputShown(false)

      if (!isLarge) {
        setIsSideMenuOpen(false)
      }

      navigate(`/search/?query=${inputValue}`)
    }

    const handleSearchClick = () => {
      if (isInputShown && inputValue.length > 0) {
        navigateToResultsPage()
      }

      setIsInputShown(!isInputShown)
      searchInputRef.current.focus()
    }

    const handleInputChange = (e) => {
      setInputValue(e.target.value)
    }

    useEffect(() => {
      if (isInputShown) {
        searchInputRef.current.focus()
      }
    }, [isInputShown])

    const getInputBackground = () => {
      if (!isInputShown) {
        return 'bg-transparent'
      }

      if (inputValue.length) {
        return 'bg-white lg:bg-whiteSemiTransparent'
      }

      return 'bg-whiteSemiTransparent'
    }

    return (
      <>
        <form action="/search" className="flex items-center justify-between">
          <input type="hidden" name="query" value={inputValue} />
          <StyledInput
            ref={searchInputRef}
            onChange={handleInputChange}
            id="search"
            className={`
            absolute z-10 w-52 lg:w-72 left-0 text-left lg:text-right pl-5 lg:pl-0 h-10 text-2xl outline-none font-thin tracking-wide
            ${isInputShown ? 'lg:show' : 'lg:hidden inset-0 w-full h-full'}
            ${getInputBackground()}
            `}
          />
          <button
            className={`${props.className} text-right flex justify-end`}
            id="search-button"
            onClick={handleSearchClick}
            type="button"
            ref={searchIconRef}
          >
            <img
              src={SearchSvg}
              className={`block lg:hidden ${props.height || 'h-8'}${
                isInputShown ? ' lg:hidden' : ' lg:inline-block'
              }`}
              alt="Search"
            />
            <img
              src={SearchSvgWithMargins}
              className={`hidden lg:block ${props.height || 'h-14'}${
                isInputShown ? ' lg:hidden' : ' lg:inline-block'
              }`}
              alt="Search"
            />
            <div
              className={`w-14 h-14 hidden justify-center items-center ${
                isInputShown ? 'lg:flex' : 'lg:hidden'
              }`}
            >
              <ActiveHamburgerIcon
                alt="Close Search"
                className="h-6 lg:h-8 transform rotate-180 text-deepDarkBlue stroke-current block"
              />
            </div>
          </button>
          <button
            type="submit"
            className="w-0 h-0 opacity-0 overflow-hidden absolute"
          >
            Submit
          </button>
        </form>
      </>
    )
  }
)

export default SearchInputButton
