import React, { FC } from 'react'

interface ISwitchProps {
  isEnabled: boolean
  onChange: (e) => any
  className?: string
  isGrayBackground?: boolean
}

const Switch: FC<ISwitchProps> = ({
  isEnabled,
  onChange,
  className,
  isGrayBackground,
}) => {
  const handleChange = (e) => {
    onChange(e)
  }

  const background = isGrayBackground ? 'bg-grayBlue' : 'bg-almostBlackBlue'

  return (
    <button
      type="button"
      onClick={() => handleChange(!isEnabled)}
      className={`relative inline-flex items-center h-5 rounded-full w-9 ${background} ${className}`}
    >
      <span
        className={`${
          isEnabled ? 'translate-x-5' : 'translate-x-1'
        } inline-block w-3 h-3 transform bg-brightYellow rounded-full transition ease-in-out duration-200`}
      />
    </button>
  )
}

export default Switch
