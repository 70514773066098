import lawsStore from 'src/components/laws/laws-store'
import { ICategory } from 'src/components/side-menu/sub-elements/Category'

const useLawChangesMenuStructure = (): ICategory => {
  const selectedSeason = lawsStore((state) => state.selectedSeason)

  return {
    name: 'Law changes',
    id: 'law-changes',
    link: `/law-changes/${selectedSeason.slug}/`,
    isSeasonsSwitch: true,
  }
}

export default useLawChangesMenuStructure
