// i18next-extract-mark-ns-start menu

import React, { FC, useEffect, useContext } from 'react'
import CategoriesContainer from 'src/components/side-menu/CategoriesContainer'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import styled from 'styled-components'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import { isLargeScreen } from 'src/helpers/layout'
import tw from 'twin.macro'
import LanguageSwitcher from 'src/components/language/LanguageSwitcher'
import {
  useTranslation,
  I18nextContext,
  Link,
} from 'gatsby-plugin-react-i18next'
// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'

export interface IMenuItem {
  name: string
  id: string
  link?: string
}

interface ISideMenuProps {}

const tailwind = resolveConfig(tailwindConfig)

const MenuNav = styled(m.nav)`
  width: 17.7rem;
  ${tw`fixed`}
  @media (min-width: ${tailwind.theme.screens.lg}) {
    width: 14rem;
  }
`

export const SHOW_UPPER_ELEMENT_ON_MOBILE = true

const SideMenu: FC<ISideMenuProps> = () => {
  const { t } = useTranslation('menu')

  const isSideMenuOpen = sideMenuStore((state) => state.isSideMenuOpen)
  const setCurrentLocationPathname = sideMenuStore(
    (state) => state.setCurrentLocationPathname
  )

  const setDynamicStyles = () => {
    const sideMenu = document.getElementById('side-menu')
    const scrollTop = window.scrollY
    const header = document.getElementById('header')
    const headerContainer = document.getElementById('header-container')
    const headerHeight = header.offsetHeight
    const headerOffset = parseInt(headerContainer.style.top, 10) || 0
    const offsetTop = Math.max(headerHeight + headerOffset, 0)

    const bodyHeight = document.querySelector('body').clientHeight
    const windowHeight = window.innerHeight
    const scrollableBottomSpace = bodyHeight - (scrollTop + windowHeight)

    const stickyFooterHeight = document.getElementById('sticky-footer')
      .offsetHeight
    const footerHeight = document.getElementById('footer').offsetHeight

    const largeScreenBottomOffset = Math.max(
      footerHeight + stickyFooterHeight - scrollableBottomSpace,
      stickyFooterHeight
    )

    const getBottomPosition = () => {
      if (!isLargeScreen()) {
        return '0'
      }

      return largeScreenBottomOffset
    }

    sideMenu.style.bottom = `${getBottomPosition()}px`
    sideMenu.style.top = `${offsetTop}px`
    sideMenu.style.height = `auto`
  }

  const navVariants = () => ({
    open: {
      opacity: 1,
      display: 'block',
      x: 0,
    },
    closed: {
      opacity: 0,
      x: isLargeScreen() ? '-100%' : '100%',
      transitionEnd: {
        display: 'none',
      },
    },
  })

  const updateLayoutDynamicValues = () => {
    /**
     * setTimeout is used to delay the execution of this method.
     * It needs to be fired after updating the position of the header in Header.tsx -> showHeaderWhenScrollingUp
     * I believe it's a temporary solution
     * TODO: remove setTimeout and prepare some more meaningfull solution.
     */
    setTimeout(() => {
      setDynamicStyles()
    })
  }

  useEffect(() => {
    updateLayoutDynamicValues()
    const globalHistoryUnsubscriber = globalHistory.listen(
      ({ location, action }) => {
        if (action === 'PUSH' || action === 'POP') {
          setCurrentLocationPathname(location.pathname)
        }
      }
    )

    window.addEventListener('resize', updateLayoutDynamicValues)
    window.addEventListener('scroll', updateLayoutDynamicValues)
    return () => {
      globalHistoryUnsubscriber()
      window.removeEventListener('resize', updateLayoutDynamicValues)
      window.removeEventListener('scroll', updateLayoutDynamicValues)
    }
  }, [])

  const { language, defaultLanguage } = useContext(I18nextContext)
  const languageSuffix = language === defaultLanguage ? '/' : `/${language}/`

  const menu = () => (
    <LazyMotion features={domAnimation}>
      <MenuNav
        id="side-menu"
        animate={isSideMenuOpen ? 'open' : 'closed'}
        initial={isSideMenuOpen ? navVariants().open : navVariants().closed}
        variants={navVariants()}
        transition={{ type: 'Inertia', stiffness: 50 }}
        className="absolute flex-col items-between content-between lg:fixed right-0 bg-almostBlackBlue lg:right-auto lg:left-0 overflow-y-auto shadow-2xl z-20 border-navyBlueGray border-b h-full"
      >
        <div className="flex lg:block h-full flex-col justify-between">
          <div>
            <CategoriesContainer />
          </div>
          <div className="b-0 l-0 r-0 absolute h-1 bg-navyBlueGray" />
          <div>
            <div className="py-3.5 px-5 lg:hidden border-t border-navyBlueGray">
              <LanguageSwitcher />
            </div>
            <div className="lg:hidden uppercase border-t border-navyBlueGray  text-navyBlueGray">
              <Link
                to="/logapp/"
                className="block py-3.5 px-5"
                activeClassName="text-brightYellow"
              >
                {t('Download mobile app')}
              </Link>
            </div>
            <div className="lg:hidden uppercase text-navyBlueGray border-t border-navyBlueGray">
              <a
                href={`https://shop.theifab.com${languageSuffix}`}
                target="_blank"
                rel="noreferrer"
                className="block py-3.5 px-5"
              >
                {t('Visit webshop')}
              </a>
            </div>
          </div>
        </div>
      </MenuNav>
    </LazyMotion>
  )

  return menu()
}

export default SideMenu
